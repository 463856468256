import React from 'react'
import styled from 'styled-components'
import { FaArrowRight } from 'react-icons/fa'
import TransitionLink from 'components/TransitionLink'

const StyledItem = styled(TransitionLink)`
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.color.backgroundsBorder};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  background-color: transparent;
  transition: background-color 200ms;
  background-color: ${({ theme }) => theme.color.backgrounds};

  :hover {
    background-color: ${({ theme }) => theme.color.accent};
  }
`

const ArrowContainer = styled.div`
  padding: 8px;
  border-radius: 50%;
  display: flex;
  background-color: ${({ theme }) => theme.color.lightLilac};
  color: ${({ theme }) => theme.color.tertiary};

  > svg {
    width: 20px;
    height: 20px;
  }
`

const OrderNumber = styled.div`
  padding-bottom: 5px;
  font-style: italic;
  font-family: ${({ theme }) => theme.font.headings};
  color: ${({ theme }) => theme.color.tertiary};
`

const TopicTitle = styled.div`
  color: ${({ theme }) => theme.color.textSecondary};
`

const formatOrderNumber = (n) => {
  if (n > 9) return n
  else return `0${n}`
}

const TopicListItem = ({ orderNumber, link, title, className }) => (
  <StyledItem to={link} className={className}>
    <div>
      <OrderNumber>{formatOrderNumber(orderNumber)}</OrderNumber>
      <TopicTitle>{title}</TopicTitle>
    </div>
    <ArrowContainer>
      <FaArrowRight />
    </ArrowContainer>
  </StyledItem>
)

export default TopicListItem
