import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { useDispatch } from 'react-redux'
import { useTrail, animated, config } from 'react-spring'
import { FaCaretRight } from 'react-icons/fa'
import RichTextRenderer from 'components/RichTextRenderer'
import Container from 'components/Container'
import Seo from 'components/Seo'
import PostLayout from 'components/PostLayout'
import PageTitle from 'components/PageTitle'
import ChapterNavigation from 'components/ChapterNavigation'
import TopicListItem from 'components/TopicListItem'
import Button from 'components/Button'
import {
  setCurrentEpisodeId,
  setIsPlaying,
  setEpisodeCurrentProgress,
} from 'state/actions'
import { sizes } from 'styles'

const Description = styled.p`
  white-space: pre-line;
`

const StyledTopicListItem = styled(TopicListItem)`
  width: 100%;

  :hover {
    opacity: 1;
  }

  @media ${sizes.smallDesktop} {
    width: 90%;
  }
`

const AnimatedTopicListItem = styled(animated.div)`
  :not(:first-of-type) {
    margin-top: 20px;
  }
`

const TopicsTitle = styled.h3`
  margin-top: 35px;
`

const Aside = styled.div`
  display: none;

  @media ${sizes.tabletLandscape} {
    display: block;

    > :not(:first-child) {
      margin-top: 40px;
    }
  }
`

const Chapter = ({ data, location }) => {
  const dispatch = useDispatch()
  const { contentfulChapter } = data
  const {
    title,
    slug,
    subtitle,
    description,
    topics,
    podcastEpisodes,
  } = contentfulChapter

  const trail = useTrail(topics?.length || 0, {
    config: config.stiff,
    delay: 300,
    opacity: 1,
    transform: 'translate3d(0px, 0px, 0px)',
    from: { opacity: 0, transform: 'translate3d(0px, 20px, 0px)' },
  })

  const getContent = () => (
    <>
      <PageTitle>{title}</PageTitle>
      <h3>{subtitle}</h3>
      {description && <Description>{description.description}</Description>}
      {topics?.length && <TopicsTitle>Topics</TopicsTitle>}
      {topics &&
        trail.map((props, i) => {
          const topic = topics[i]
          return (
            <AnimatedTopicListItem key={i} style={props}>
              <StyledTopicListItem
                link={`/chapter/${slug}/${topic.slug}`}
                title={topic.title}
                orderNumber={i + 1}
              />
            </AnimatedTopicListItem>
          )
        })}
    </>
  )

  const getAsideContent = () => (
    <Aside>
      <ChapterNavigation location={location.pathname} />
      <div>
        {podcastEpisodes &&
          podcastEpisodes.map((podcast, i) => (
            <div key={i}>
              <RichTextRenderer richText={podcast.previewText.json} />
              <Button
                variant="secondary"
                onClick={() => {
                  dispatch(setCurrentEpisodeId(podcast.id))
                  dispatch(setEpisodeCurrentProgress(podcast.id, 0))
                  dispatch(setIsPlaying(true))
                }}
              >
                Play
                <FaCaretRight size={16} />
              </Button>
            </div>
          ))}
      </div>
    </Aside>
  )

  return (
    <Container>
      <Seo
        title={title}
        image={{
          src:
            podcastEpisodes && podcastEpisodes[0].image
              ? podcastEpisodes[0].image.resize.src
              : undefined,
          width: 1600,
          height: 900,
        }}
        description={
          description
            ? description.description.substr(0, 157) + '...'
            : undefined
        }
      />
      <PostLayout mainContent={getContent()} asideContent={getAsideContent()} />
    </Container>
  )
}

export const chapterQuery = graphql`
  query chapterQuery($slug: String!) {
    contentfulChapter(slug: { eq: $slug }) {
      id
      slug
      title
      subtitle
      description {
        description
      }
      topics {
        title
        slug
      }
      podcastEpisodes {
        id
        previewText {
          json
        }
        image {
          resize(width: 1600, height: 900) {
            src
          }
        }
      }
    }
  }
`

export default Chapter
